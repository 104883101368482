<template>
<div class="tgju-widget last-post light">
    <div class="list-widget  list-row bold list-row-title border-0">
        <div class="list-col l-title dot head-color no-border">
            <span>آخرین تحلیل‌های دنبال‌شوندگان</span>
        </div>
        <div class="list-col l-item  head-color">
            <i class="uil uil-comment-alt-lines"></i>
        </div>
    </div>
    <div class="tgju-widget-content full-padding">
        <div v-if="widgetLoad" class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-else-if="posts.length">
            <div v-for="post in posts" :key="post.id" class="list-row d-block hover">
                <router-link v-if="isMyPost(post.user.username)" :to="{ name: 'Profile' }" class="link-title">
                    <div v-html="post.content"></div>
                </router-link>
                <router-link v-else :to="{ name: 'ProfileOthers', params: { username : post.user.username }}" class="link-title">
                    <div v-html="post.content"></div>
                </router-link>
                <div class="link-items">
                    <i class="uil uil-user link-item-icon"></i>
                    <span v-tooltip="'کاربر'" class="badge badge-primary"> {{ post.user ? post.user.username : post.name}}</span>
                    <div v-tooltip="'بازار ارسالی'" :class="'badge lastpost-badge ' + post.post_group.title">
                        <slot v-for="discussion in discussions">
                            <slot v-if="discussion.text == post.post_group.title">
                                {{discussion.value}}
                            </slot>
                        </slot>
                    </div>
                    <span class="time">
                        <div v-tooltip="'تاریخ ارسال'">{{ toRelative(post.created_at) }}</div>
                    </span>
                </div>
            </div>
        </slot>
        <div v-else class="text-center p-2">
            <div class="not-found-items">
                <i class="uil uil-exclamation"></i>
                مقداری یافت نشد.
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این کامپونتت برای نمایش لیست  نظرات ارسالی دوستان کاربر می باشد
import moment from "moment-jalaali";
export default {
    name: "LastAnalysis",
    components: {},
    props: ['username'],
    data: function () {
        return {
            posts: {
                id: undefined,
                length: undefined,
                created_at: undefined,
                user: {
                    username: undefined,
                },
            },
            widgetLoad: true,
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' }
            ],
        };
    },
    mounted() {
        // در خواست دریافت اطلاعات از سرور
        this.getLastPost();
    },
    methods: {
        toRelative(date) {
            return moment(date).fromNow();
        },
        // وظیفه این متد دریافت اطلاعات از سرور می باشد
        getLastPost() {
            //ارسال درخواست ای پی آی
            this.$helpers.makeRequest("GET", "/tgju-post/last-comments", {
                    'username': this.username
                })
                .then((api_response) => {
                    if (api_response.data.response.status == "success") {
                        this.posts = api_response.data.response.detail;
                        this.widgetLoad = false;
                    }
                });
        },
        // وضعیت پست های مربوط به پروفایل خودی را برمی گرداند
        isMyPost(data) {
            return this.$store.state.user.username == data;
        },
    },
};
</script>
